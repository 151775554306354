<template>
  <section class="container section has-text-left">
    <!-- Extra break spacing so that when navigating with # ids, the navbar's border does not touch the content so closely -->
    <br />

    <!-- Section heading -->
    <h1 class="title" style="color: #e81050">Who are we</h1>

    <p class="subtitle">
      Global consultancy solving complex problems with technology, helping our
      clients
      <span class="highlight"
        >create seamlessly intuitive digital experiences</span
      >
      with our extensive experiences. We partner with our clients to
      continuously evolve their tech while enabling an adaptive mindset to meet
      their business goals.
      <br />
      <br />
      We specialize in building
      <span class="highlight">Beautiful Bespoke software</span> with your
      requirements and while exceeding your expectations.
    </p>
  </section>
</template>

<script>
export default {
  name: "WhoAreWe",
};
</script>
