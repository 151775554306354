// Function to redirect user to the preferred domain instead of the default firebase hosting domains if they land there.
// IIFE to prevent polluting the global namespace accidentally
(function () {
  const location = window.location.hostname;
  console.log(`Window location: ${location}`);

  if (
    location === "ekd-solutions.web.app" ||
    location === "ekd-solutions.firebaseapp.com"
  )
    window.location.replace("https://solutions.enkeldigital.com");
})();

import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  mounted() {
    // This is needed for "prerender-spa-plugin"'s renderAfterDocumentEvent to take snapshot of static content once everything is rendered.
    document.dispatchEvent(new Event("vue-render-complete"));
  },
}).$mount("#app");
